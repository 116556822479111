import React, { useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import DownloadIcon from "assets/downloadtool.jpg";
import CopyIcon from "assets/copytools.jpg";
import { Languages } from "components/QualityTools/LanguagesData";
import * as XLSX from "xlsx";
import DownloadSection from "utils/resultDownload";

const LanguageTranslationOutput = ({ translateText,outputLang }) => {
  const [copySuccess, setCopySuccess] = useState("Copy");
    const [selectedFormat, setSelectedFormat] = useState("Download CSV");
    const downloadOutputFormat = ["Download CSV", "Download XLS"];
  const outputRef = useRef(null);
  const copyText = () => {
    if (outputRef.current) {
      const textToCopy = outputRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };
  const handleFormatChange = (format) => {
    setSelectedFormat(format);
  };

  const handleDownload = (format) => {
    if (format === "Download CSV") {
      downloadCSV();
    } else if (format === "Download XLS") {
      downloadXLS();
    }
  };

  const downloadCSV = () => {
    const textContent = translateText;
    const blob = new Blob([textContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "translation_output.csv";

    link.click();
  };
  const downloadXLS = () => {
    const textContent = translateText;

    let formattedContent = [];
  
    if (typeof textContent === 'string') {
      formattedContent = [{ text: textContent }];  
    } else if (Array.isArray(textContent)) {
    
      if (typeof textContent[0] === 'object') {
        formattedContent = textContent; 
      } else {
       
        formattedContent = textContent.map(item => ({ text: item })); 
      }
    } else {
      console.error("translateText is neither a string nor an array.");
      return;
    }

    const ws = XLSX.utils.json_to_sheet(formattedContent);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "translation_output");

    XLSX.writeFile(wb, "translation_output.xlsx");
  };
  
  

  if (outputLang.startsWith('en')) {
    outputLang = 'en';  
  }
      const language = Languages.find((lang) => lang?.code === outputLang);

  return (
    <div className=" bg-white mt-4  px-5   text-[#6E6E6E] font-opensans  text-base  border-none outline-none block rounded-lg shadow-lg w-full">
      <div className="flex justify-between gap-4 py-4 border-b-2 border-[#B0B0B0]">
        <span>Sample Translation - {language?.name}</span>

        <div className="flex gap-4 items-center justify-between  ">
          <button
            data-tooltip-id="copy-tooltip"
            data-tooltip-content={copySuccess}
            onClick={copyText}
            className=""
          >
            <img src={CopyIcon} alt="/copyicons" />
          </button>

            <DownloadSection
                handleDownload={handleDownload}
                selectedFormat={selectedFormat}
                handleFormatChange={handleFormatChange}
                downloadOutputFormat={downloadOutputFormat}
              />
        </div>
        <Tooltip
          id="copy-tooltip"
          place="top"
          multiline={true}
          className="z-50"
        />
        <Tooltip
          id="download-tooltip"
          place="top"
          multiline={true}
          className="z-50"
        />
      </div>
      <textarea
        ref={outputRef}
        value={translateText}
        readOnly
        className="anonymize_tool  py-5  min-h-[240px] bg-transparent text-base overscroll-y-none resize-none border-none outline-none block  w-full"
      />

      <div>
        <p className="text-start py-4 text-sm text-[#9C9C9C] font-semibold font-opensans">
          {
           translateText?.split(/\s+/).filter((word) => word?.length > 0)
              ?.length
          }{" "}
          words
        </p>
      </div>
    </div>
  );
};

export default LanguageTranslationOutput;
