import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLanguageIdentifier } from "hooks/Forms/useLanguageIdentifier";
import Loader from "components/loader";
import formCommonPostData from "utils/form-common-post-data";
import Reanalysisicon from "assets/reanalysis_icon.svg";
import { Link, navigate } from "gatsby";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
// import { Languages } from "../QualityTools/LanguagesData";
import http from "utils/http";
import { useLocation } from "@reach/router";
import { endpoints } from "utils/endpoints";
import CleanButton from "assets/cleanBtn.svg";
import Layout from "components/layout";
import SEO from "components/seo";
import { direction } from "utils/direction";
import LanguageTranslationOutput from "components/languageIdentifier/languageTranslationOutput";

const LanguageIdentifier = () => {
  const { status, mutateAsync } = useLanguageIdentifier();
  const [toggleTool, setToggleTool] = useState(false);
  const [summarizedText, setSummarizedText] = useState("");
  const [detectedLanguage, setDetectedLanguage] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [shareId, setShareId] = useState("");
  const [error, setError] = useState(false);
  const [inputText, setInputText] = useState("");
  const [translateText, setTranslateText] = useState("");
    const [outputLang,setOutputLang] = useState("")

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (inputText.trim() === "") {
      alert("Please enter text in the textarea box");
      return false;
    }

    setLoading(true);

    const postData = {
      tool_name: "language_detector",
      user_text: inputText,
      source_language_code:inputLang,
      ...formCommonPostData(),
    };

    try {
      const response = await mutateAsync(postData);
      setLoading(false);
      const newShareId = response?.meta?.share_id;
      setShareId(newShareId);
      setToggleTool(true);
      
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const UpdatreshareId = params.get("share-id");

  const displayShareId = shareId ? shareId : UpdatreshareId;
  useEffect(() => {
    if (!displayShareId) return;
    const fatchAnalysis = async () => {

      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );
        
        const { user_text: sourceText,source_language_code:sourceCode} = response?.meta;
        setInputLang(sourceCode)
        setTranslateText(response?.result?.translatedText)
        setOutputLang(response?.meta?.target_language_code)
        let detectedLanguages;

        if (typeof response?.result === "string") {
          const cleanedResult = response.result.replace(/```json\n|\n```/g, "");
          detectedLanguages = JSON.parse(cleanedResult).detected_languages;
        } else if (response?.result?.detected_languages) {
          detectedLanguages = response.result.detected_languages;
        }

        setDetectedLanguage(detectedLanguages || []);

        // Set the textarea value to the fetched sourceText
        setWordCount(
          sourceText
            ? sourceText.split(/\s+/).filter((word) => word.length > 0).length
            : 0
        );
        // reset({ message: sourceText });
        setInputText(sourceText);

        let finalText = "";
        response.data.language_probability.forEach((k) => {
          const [key] = Object.entries(k);
          finalText += ` Most Likely ${key[0]} - ${Math.ceil(key[1] * 100)}%. `;
          setSummarizedText(finalText);
        });

        setToggleTool(true);
        reset();
        setWordCount(0);
        window.scrollTo(0, 0);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };
    fatchAnalysis();
  }, [displayShareId]);

  const handleTextChange = (e) => {
    const text = e.target.value;
    setInputText(text);
  };
  useEffect(() => {
    const words = inputText.split(/\s+/).filter((word) => word.length > 0);
    setWordCount(words.length);
    // setDetectedLanguage("");
  }, [inputText]);

  const handleImageClick = () => {
    if (!detectedLanguage) return;

    handleSubmit(onSubmit)();
  };
  if (loading)
    return (

        <div className="grid place-content-center h-[calc(100vh-150px)]">
          <Loader />
        </div>
   
    );
  return (
    <Layout>
    <SEO
      title="Detect Language – Instant Language Detector"
      description="Instantly detect and identify the languages used in a text. Use our language detection tool to quickly find out what languages you're dealing with. Try it for free."
      slug="/tools/language-detector"
    />
    <div className="bg-[#F5F5F5] ">
      <div className="w-full  py-5 ">
        <ToolsBreadcrumb />
      </div>

      <div className="py-10  max-w-7xl mx-auto px-4 grid lg:grid-cols-2 gap-12">
        <div>
          <h1 className="text-[#5B93FF] mt-0 lg:mt-4 text-center md:text-start text-base font-primary font-semibold leading-6 mb-2.5">
            DETECT LANGUAGE
          </h1>
          <h2 className="text-[#0A2641] text-center md:text-start font-bold text-2xl lg:text-[48px] leading-[48px] lg:leading-[56px] font-primary">
            Instant language detection of your text
          </h2>
          <p className="hidden md:block text-[#424242] text-center md:text-start text-lg font-opensans leading-[26px] pt-4">
            Detect the language of your text instantly, whether it’s in English,
            Spanish, or other languages. Enter your text, and click the button
            to find out what language your text is in. This language detector
            can identify multiple languages present in a given text.
          </p>
        </div>
        <div>
          <form
            action=""
            className="relative shadow-lg rounded-lg bg-white"
            onSubmit={handleSubmit(onSubmit)}
          >
            <textarea
              placeholder="Type your text here"
              name="message"
              id="message"
              dir={direction(inputLang)}
              className={`anonymize_tool ${direction(inputLang) === "rtl" ? "pl-8 pr-5" : "pl-5 pr-8" } py-5 min-h-[400px] lg:min-h-[240px] placeholder-[#B4B4B4] text-black font-primary bg-transparent text-[15px] overscroll-y-none resize-none border-none outline-none block w-full`}
              onChange={handleTextChange}
              value={inputText}
            ></textarea>

            {wordCount > 0 && (
              <p
                className={`absolute top-[14px]  w-max cursor-pointer ${direction(inputLang) === "rtl" ? "left-[10px]" : "right-[16px]" }`}
                onClick={() => navigate(`/tools/language-detector/`)}
              >
                <img src={CleanButton} alt="clean-button" />
              </p>
            )}

            <div className="flex justify-between items-center p-4">
              <p className="text-right mt-2 text-sm text-[#9C9C9C]">
                word count: {wordCount}
              </p>

              <button
                type="submit"
                disabled={!!detectedLanguage}
                className={` ${
                  detectedLanguage
                    ? "bg-[#E8E8E8] text-[#858585] cursor-not-allowed "
                    : "bg-lightBlue text-white"
                } text-base font-semibold  font-opensans text-center rounded-md block px-6 py-2`}
              >
                {/* {loading ? <Loader /> : "Detect language"} */}
                Identify language
              </button>
            </div>
          </form>
          <div className="mt-4 rounded-lg bg-white w-full px-4 py-6 shadow-lg">
            <div className="w-full md:flex justify-between items-center">
              <p className="text-[#858585] font-opensans text-semibold leading-6">
                Languages detected:
              </p>

              <div className="text-[#858585] mt-4 md:mt-0 font-opensans text-semibold leading-6">
                {status === "loading" ? (
                  <p className="flex">
                    Loading...
                    <Loader />
                  </p>
                ) : (
                  <div className="flex flex-col gap-2 ">
                    <p className="flex items-center gap-2 w-max">
                      <img
                        src={Reanalysisicon}
                        alt="Reanalyze"
                        className="cursor-pointer"
                        onClick={handleImageClick}
                      />
                    </p>
                  </div>
                )}
              </div>
            </div>
            {detectedLanguage ? (
              <div className="flex flex-col gap-2 mt-4">
                {detectedLanguage.map((name, i) => (
                  <div key={i} className="flex gap-2 items-center">
                    <span className="text-[#27862A] text-lg font-bold leading-6 font-opensans">
                      {name.language}
                    </span>
                    <span className="text-[#27862A] text-lg font-bold leading-6 font-opensans">
                      {name.percentage}%
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
                {error}
              </span>
            )}
           
          </div>
          {translateText && (
          <LanguageTranslationOutput translateText={translateText} outputLang={outputLang} />
        )}
          <p className="w-full text-center text-base mt-12 pb-4 text-[#9C9C9C]">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
        </div>
        <p className="block md:hidden text-[#424242] text-center md:text-start text-lg font-opensans leading-[26px] pt-4">
          Detect the language of your text instantly, whether it’s in English,
          Spanish, or other languages. Enter your text, and click the button to
          find out what language your text is in. This language detector can
          identify multiple languages present in a given text.
        </p>
      </div>
    </div>
    </Layout>
  );
};

export default LanguageIdentifier;
